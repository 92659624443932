.sidebar-text {
  span {
    font-size: 14px;
    text-transform: uppercase;
  }
}

.active-menu-item {
  span {
    color: #000000;
  }

  svg {
    color: #000000;
  }
}

.passive-menu-item {
  display: none;
  span {
    color: #ffffff;
  }

  svg {
    color: #ffffff !important;
    rect {
      stroke: #ffffff !important;
    }
  }
}
