.root {
  margin: 30px;
  color: #ffffff;
  overflow-y: auto;
  padding-right: 10px;
}

.single-organization-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.single-organization-content {
  width: 100%;
  margin-top: 15px;
  height: 94% !important;
  display: flex;
  justify-content: space-between;

  &-left {
    width: 66%;
    margin: 0;
  }

  &-right {
    width: 33%;
    margin: 0;
  }

  &-card {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }
}

.back-button {
  background-color: #000 !important;
  color: #fff !important;
  display: flex;
  justify-content: start;
  align-items: center;

  &:hover {
    background-color: rgba(100, 100, 100, 0.45) !important;
  }

  svg {
    margin-right: 5px;
  }
}
