.single-organization-content-card {
  .title {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .card-content {
    margin: 10px 20px;
    border-bottom: 1px solid #363636;
    display: flex;
    justify-content: start;
  }

  .server-details {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .server-info,
    .server-storage {
      width: 50%;
      display: flex;
      flex-direction: column;
    }

    .server-info {
      .info-label-dedicated {
        padding-block: 10px;
        margin: 0;
      }
    }
    .info-label {
      color: #9e9e9e;
      font-size: 14px;
    }

    .server-url {
      padding-block: 5px;
      font-size: 12px;
    }
  }

  .card-actions {
    margin: 10px 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .server-description {
    width: 48%;
    color: #9e9e9e;
    font-size: 14px;
  }
}
