.CorporateContainer {
  width: 100%;
  height: max-content;
  margin-bottom: 25px;
  display: flex;
  justify-content: start;
  gap: 20px;
}

.CorporateContainerLeftSide,
.CorporateContainerRightSide {
  width: 50%;
  background: #1d1d1d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 600px !important;
  margin: 0;
}

.CorporateContainerLeftSideUpload,
.CorporateContainerRightSideUpload {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-block: 40px;
}

.CorporateContainerLeftSideActions,
.CorporateContainerRightSideActions {
  width: 100%;
  padding: 50px;
  display: flex;
  justify-content: end;
}

.CorporateContainerRightSide {
  height: 550px;
}
