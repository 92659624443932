.info-label {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;

  &__title {
    font-size: 13px;
    color: #9e9e9e;
    margin-right: 8px;
    padding-block: 4px;
  }

  &__value {
    font-size: 14px;
    color: #ffffff;
    font-weight: 600;
    padding-block: 4px;
  }
}
