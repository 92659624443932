.app__blackPinTag {
  background-color: #e7b14f;
  border-radius: 40px;
  line-height: 14px;
  text-align: center;
  padding: 5px;
  width: 75%;

  label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
  }
}
