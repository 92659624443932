.MuiMultiSectionDigitalClockSection-root,
.MuiMultiSectionDigitalClockSection-root ul,
.MuiMultiSectionDigitalClockSection-root li {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar,
.MuiMultiSectionDigitalClockSection-root ul::-webkit-scrollbar,
.MuiMultiSectionDigitalClockSection-root li::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

/* Hide scrollbars for specific datepicker elements */
