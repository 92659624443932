.single-organization-content-card {
  .title {
    font-weight: bold;
    margin-bottom: 10px;
  }
  .card-content-contract {
    border-bottom: 1px solid #363636;
    margin-inline: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .contract-dates {
      display: flex;
      justify-content: space-between;

      p {
        font-size: 14px;
        margin: 0;
      }

      .info-label {
        color: #9e9e9e;
      }
    }
  }

  .contract-details-service-package {
    display: flex;
    padding-block: 20px;
    justify-content: space-between;
    border-block: 1px solid #363636;
    margin-block: 20px;

    .info-label {
      width: 50%;
    }
  }

  .contract-contacts {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .contact-info {
      width: 50%;
      display: flex;
      flex-direction: column;

      .info-label {
        font-size: 14px;
        color: #9e9e9e;
      }

      .contact-name {
        font-size: 14px;
        margin-block: 10px;
      }

      .contact-email {
        font-size: 14px;
        text-decoration: underline;
      }
    }
  }
}
