.statistics {
  color: #ddd;
  margin-bottom: 0px;
  width: 50%;
  margin-inline: 0;
}

h2 {
  margin-bottom: 10px;
}

.stats-grid {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.stat-item {
  padding-bottom: 5px;
}

.stat-value {
  color: white;
  font-size: 14px;
}
