.single-organization-content-card {
  .title {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .card-content {
    display: flex;
    justify-content: space-between;
  }

  .client-details {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .client-info,
    .technical-contact {
      width: 50%;
      display: flex;
      flex-direction: column;
    }

    .client-name {
      font-size: 14px;
      font-weight: 700;
    }

    .client-address {
      font-size: 14px;
      width: 150px;
      margin-top: 15px;
      line-height: 22px;
    }

    .info-label {
      color: #9e9e9e;
      font-size: 13px;
    }

    .contact-name {
      font-size: 14px;
      margin-block: 10px;
    }

    .contact-email {
      font-size: 13px;
      text-decoration: underline;
    }
  }
}
