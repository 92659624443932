.single-organization-content-card {
  .title {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .card-content {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-inline: 20px;
    padding-bottom: 40px;
    border-bottom: 1px solid #363636;
    width: auto !important;
  }

  .organization-details {
    display: flex;
  }

  .organization-image {
    width: 46px;
    height: 46px;
  }

  .value {
    font-weight: bold;
    padding-top: 10px;
    padding-left: 20px;
  }

  .card-actions {
    margin: 20px 15px;
    display: flex;
    flex-direction: column;
  }

  .info {
    color: #9e9e9e;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;

    .info-title {
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 14px;
    }

    .info-content {
      color: #9e9e9e;
    }
  }
}
