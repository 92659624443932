.single-organization-content-card {
  .title {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .card-content {
    margin: 10px;
    border-bottom: 1px solid #363636;
    display: flex;
    justify-content: start;
    width: 100%;
  }

  .authorization-details {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .authorization-info {
      width: 50%;
      font-size: 14px;
      color: #9e9e9e;
    }
  }
}
