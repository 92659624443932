.page-container {
  background-color: black;
  color: white;
  padding: 20px;
  width: 80%;
}

.editor-container {
  background-color: white;
  color: black;
  border: 1px solid #ddd;
  font-size: 14px;
  height: 493px !important;
  width: 100%;

  .quill {
    height: 450px;
  }
}

.editor-container .quill {
  height: 450px;
}

.action-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
